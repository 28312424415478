<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->

            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0 text-white">Kampaň</h3>
                    <h2 class="">{{campaign.title}}</h2>
                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-6 col-md-9 col-lg-12  order-xl-1">
                    <a-card shadow type="secondary">
                        <template>
                            <form @submit.prevent="handleSubmit">
                                    <div class="row" v-if="campaign">
                                        <div class="col-lg-12">
                                        <template>
                                    <div class="mb-4" >
                                        <label class="form-control-label">Názov kampane</label>
                                        <a-input alternative=""
                                                 input-classes="form-control-alternative"
                                                 v-model="campaign.title"
                                        />
                                    </div>
                                    <div class="mb-4">
                                        <label class="form-control-label">Typ akcie</label>
                                        <a-select defaultValue="1" class=" w-100" v-model="campaign.type" :options="types"/>
                                    </div> <template>
                                    <div class="mb-4">
                                        <label class="form-control-label">Platí pre izby</label>
                                        <a-select defaultValue="1" mode="multiple" class=" w-100" v-model="campaign.rooms" :options="contenttypes"/>
                                    </div>
                                </template>
                                    <div class="mb-4">
                                        <label class="form-control-label">Banner</label>
                                        <image-upload v-if="campaign.image" :data="JSON.parse(campaign.image)"  title="Baner s akciou" v-model="campaign.image" @changed="(e) => onChildChange(e,'image')"  dat="image"></image-upload>
                                        <image-upload v-else title="Baner s akciou" v-model="campaign.image" @changed="(e) => onChildChange(e,'image')"  dat="image"></image-upload>
                                    </div>
                                    <div class="mb-4">
                                        <label class="form-control-label w-100">Termín platnosti</label>
                                        <a-range-picker class="mb-3" :default-value="[moment(campaign.valid[0]),moment(campaign.valid[1])]" :locale="locale"  @change="(e) => datepickerchange(e,'valid')"/>
                                    </div>
                                    <div class="mb-4">
                                        <label class="form-control-label w-100">Nákup izby v termíne</label>
                                        <a-range-picker class="mb-3" v-if="campaign.rangefrom" :default-value="[moment(campaign.daterange[0]),moment(campaign.daterange[1])]" :locale="locale"  @change="(e) => datepickerchange(e,'daterange')"/>
                                        <a-range-picker class="mb-3" v-else  :locale="locale" @change="(e) => datepickerchange(e,'daterange')"/>
                                    </div>
                                    <a-checkbox class="mb-3" v-model="campaign.active">
                                        Kampaň aktívna
                                    </a-checkbox>
                                </template>
                                        </div>
                                </div>
                                <div class="text-right">
                                    <a class="btn btn-secondary" :href="'/translate/' + this.id" v-if="fieldData['language'] === 'sk'" >Preložiť</a>   <button class="btn btn-primary">Uložiť</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getURL} from "../api";
    import {dataService} from "../_services";
    import Image from "./content/Image";
    import FormItem from 'ant-design-vue'
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import ADatePicker from "ant-design-vue/es/date-picker";

    import Multi from "./content/multi";
    import moment from 'moment'

    import 'moment/locale/sk';
    import Vue from "vue"
    Vue.use(FormItem);
    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Začiatok", "Koniec"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storočie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }
    let id = 0;
    export default {
        name: 'variables',
        props: {id},
        components: {Multi,AFormItem,'image-upload' : Image,'a-range-picker': ADatePicker.RangePicker},
        data() {
            return {
                url: getURL(),
                colorsto: JSON.parse(localStorage.colors),
                fieldData: {},
                types:[{"value":"1","label": "Akcia 2 + 1 zadarmo"},{"value":"2","label": "Služba v cene"}],
                contenttypes:[],
                campaign: {daterange:[]},
                options:[],
                moment: moment,
                locale,
                dateFormat: 'YYYY-MM-DD',
            styles: {'name': [],'width': [],'height': []},

                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 4},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 20},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 4},
                    },
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'dynamic_form_item'});
            this.form.getFieldDecorator('keys', {initialValue: [this.styles], preserve: true});
        },
        methods: {

            datepickerchange(e,slug)
            {
                console.log(e);
                this.campaign[slug] = [e[0]._d,e[1]._d];
            },
            remove(k) {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                // We need at least one passenger
                if (keys.length === 1) {
                    return;
                }

                // can use data-binding to set
                form.setFieldsValue({
                    keys: keys.filter(key => key !== k),
                });
            },
            varItems(e,slug){
                this.fieldData[slug].value = JSON.stringify(this.styles);
            },
            onChildChangeMulti(value,slug)
            {

                for(var i in value){
                    this.fieldData[slug].value = JSON.stringify(Object.values(value[i]));
                }

            },
            add() {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                const nextKeys = keys.concat(id++);
                // can use data-binding to set
                // important! notify form to detect changes
                form.setFieldsValue({
                    keys: nextKeys,
                });
            },
            onChildChange(value,slug) {
                this.campaign[slug] = JSON.stringify(value);
            },

            handleSubmit() {
                dataService.axiosPost("addcampaign", this.campaign).then(results => {
                    if (results.data.success) {
                        this.$message.success('Nastavenia boli uložené');
                        this.fetch();
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },

            fetch() {
                this.loading = true;
                dataService.axiosFetch('campaign',this.id).then(results => {
                    this.campaign = results;
                    this.loading = false;
                });
            }
        },
        mounted() {
            this.fetch();
            dataService.axiosFetch("content","typy_izieb").then(results => {
                var roleops = [];
                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });
                this.contenttypes = roleops;
            });

        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .multi-items
    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;
    }
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button
    { position: relative;
        top: -6px;}

    .style-prop
    {float:left;width:20%;margin-right:1%;}
    .style-name
    {float:left;width:50%;
        margin-right:1%;}
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

</style>
